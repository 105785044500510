import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { AccountForm } from '../../../../components/AccountForm';
import { UserRepositoryType } from '../../../../contracts/repositories';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let ChangeNicknameView = class ChangeNicknameView extends Vue {
    constructor() {
        super(...arguments);
        this.formName = 'updateProfileForm';
        this.nickname = '';
        this.successMessage = '';
        /**
         * Determines whether the component is currently performing any async actions.
         */
        this.isActing = false;
    }
    /**
     * Loads current nickname from store.
     */
    get currentNickname() {
        const user = this.$store.getters['profile/me'];
        return user === null || user === void 0 ? void 0 : user.nickname;
    }
    /**
     * Handles the `@cancel` event on the `<AccountForm>` component.
     */
    onCancel() {
        this.$router.push({ name: 'profile.my-data' });
    }
    /**
     * Handles the `@submit` event on the `<AccountForm>` component.
     */
    onSubmit() {
        this.isActing = true;
        this.successMessage = '';
        this.userRepository.update({ nickname: this.nickname })
            .then(() => {
            this.successMessage = `${this.$t('modules.profile.views.change-nickname.success')}`;
        })
            .catch(error => this.$logger(error, 'warn'))
            .finally(() => {
            this.isActing = false;
        });
    }
    /**
     * Updates nickname to current nickname loaded from store.
     * @param currentNickname
     * @private
     */
    loadNickname(currentNickname) {
        this.nickname = currentNickname;
    }
};
__decorate([
    Inject(UserRepositoryType)
], ChangeNicknameView.prototype, "userRepository", void 0);
__decorate([
    Watch('currentNickname')
], ChangeNicknameView.prototype, "loadNickname", null);
ChangeNicknameView = __decorate([
    Component({ name: 'ChangeNicknameView', components: { AccountForm, Screen } })
], ChangeNicknameView);
export { ChangeNicknameView };
export default ChangeNicknameView;
