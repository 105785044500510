import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { ValidationType } from '@movecloser/front-core';
/**
 * Helper component that every `<form>` inside `<MyAccountView>` should use.
 *
 * @emits cancel - When the User clicks the "cancel" button.
 * @emits submit - When the `<form>` gets submitted.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let AccountForm = class AccountForm extends Vue {
    constructor() {
        super(...arguments);
        this.errors = [];
    }
    mounted() {
        if (this.formName) {
            this.subscription = this.validation.onFormErrors(this.formName, (newErrors) => {
                newErrors.shift(); // omit global error message
                this.errors = [...newErrors];
            });
        }
    }
    unmounted() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    /**
     * Handles the `@click` event on the "cancel" button.
     */
    onCancelBtnClick() {
        this.$emit('cancel');
    }
    /**
     * Handles the `@submit` event on the `<form>` element.
     */
    onSubmit() {
        this.errors = [];
        this.$emit('submit');
    }
};
__decorate([
    Prop({ type: String, required: false, default: '' })
], AccountForm.prototype, "formName", void 0);
__decorate([
    Prop({ type: String, required: false, default: '' })
], AccountForm.prototype, "successMessage", void 0);
__decorate([
    Prop({ type: String, required: false, default: '' })
], AccountForm.prototype, "submitLabel", void 0);
__decorate([
    Inject(ValidationType)
], AccountForm.prototype, "validation", void 0);
AccountForm = __decorate([
    Component({ name: 'AccountForm' })
], AccountForm);
export { AccountForm };
export default AccountForm;
